<template>
	<div class="pagesbox">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<div class="banner">
			<div class="bannerbox flex">
				<!-- <span class="bannerbox-tit">销售实战案例</span> -->
				<span class="bannerbox-tit">剖析行业销售场景，助力企业实现数字化转型</span>
				<div class="sqsy" @click="applyFromshow = true">免费咨询</div>
			</div>
			<img src="../assets/img/casebanner.png">
		</div>

		<!-- <div class="casebody">
			<div class="tit">
				已帮助<span style="color: #0077FF;">10000+</span>企业实现销售数字化升级
			</div>

			<div class="casebody-cent flex">
				<div class="casebody-cent-cell" v-for="(item,index) in list" :key="index">
					<img :src="item.imgs">
					<div style="padding: 0.10rem;font-size: 0.14rem;font-weight: 400; color: #333;">{{item.title}}</div>
					<div style="padding: 0.10rem 0.15rem;font-size: 0.12rem;color: #666;">
						{{item.lable}}
					</div>
					<div style="width: 90%;justify-content: space-between;margin: 0 auto;padding: 0.20rem 0;" class="flex">
						<span style="font-size: 0.12rem;color: #999;">科技服务</span>
						<img style="width: 0.70rem;" src="../assets/img/dyw.png" >
					</div>
				</div>
			</div>
			
			<div class="more">
				<div class="more-cell">查看更多</div>
			</div>
		</div> -->

		<!-- <div class="enterprise">
			<div class="tit">
				他们也选择了力瀚
			</div>
			<div style="width: 50%;margin:0 auto;padding-bottom: 1.00rem;padding-top: 0.50rem;">
				<img src="../assets/img/logo_wall.png" >
			</div>
		</div> -->

		<div class="typicalCase">
			<div class="tit">
				典型案例
			</div>
			<div class="cell-le-lab">聚焦当下，着眼未来。力瀚，为企业发展出谋划策，为企业成长保驾护航，全程陪伴共赢未来！</div>
			<div class="typicalCase-box flex" style="position: relative;">
				<img class="typicalCase-box-img"
					:src="require('../assets/img/typicalCaseimg_'+ typicalCaseIndexLeft +'.png')">
				<el-button icon="el-icon-arrow-left" circle style="margin: 0 0.2rem;" @click="Left"></el-button>
				<img class="typicalCase-box-centimg"
					:src="require('../assets/img/typicalCaseimg_'+ typicalCaseIndex +'.png')">
				<el-button icon="el-icon-arrow-right" circle style="margin: 0 0.2rem;" @click="Right"></el-button>
				<img class="typicalCase-box-img"
					:src="require('../assets/img/typicalCaseimg_'+ typicalCaseIndexRight +'.png')">
				<div
					style="position: absolute;bottom: 0;display: flex;width: 3.3rem;margin: 0 auto;justify-content: space-between;margin-bottom: 0.1rem;margin-right: 2rem;">
					<div v-for="(item,index) in typicalCaseList" :key="index" @click="Left(),typicalCaseIndex=index+1"
						style="width: 0.1rem; height: 0.10rem; border: 0.01rem solid #fff;border-radius: 100%;"
						:style="{'background-color':(typicalCaseIndex == index+1 ? '#fff !important' : 'transparent')}">
					</div>
				</div>
				<!-- <el-carousel :autoplay="false" type="card" arrow="always">
				      <el-carousel-item v-for="(item,index) in typicalCaseList" :key="index">
				        <img :src="item.imgs">
				      </el-carousel-item>
				    </el-carousel>

				<!-- <div class="bannerSW">
					<transition name="bannerSW">
						<div class="img" v-for="(item, index) in typicalCaseList" v-if="num==index" :key="index">
							<img :src="item.imgs">
							<!--  -->
				<!-- </div> -->
				<!-- </transition> -->
				<!-- </div> -->

			</div>
		</div>

		<div class="Exhibition">
			<div class="tit">
				商城案例展示
			</div>
			<div class="Exhibition-box flex">
				<div>
					<img class="Exhibition-box-img" style="width: 2.98rem;"
						src="../assets/img/case-Exhibition-img1.png">
					<div class="Exhibition-box-test">上海vivo会员商城</div>
				</div>
				<div>
					<img class="Exhibition-box-img" style="width: 2.98rem;"
						src="../assets/img/case-Exhibition-img2.png">
					<div class="Exhibition-box-test">车脉盟会员商城</div>
				</div>
				<div>
					<img class="Exhibition-box-img" style="width: 2.98rem;"
						src="../assets/img/case-Exhibition-img3.png">
					<div class="Exhibition-box-test">青浦医院员工福利商城</div>
				</div>
				<div>
					<img class="Exhibition-box-img" style="width: 2.98rem;"
						src="../assets/img/case-Exhibition-img4.png">
					<div class="Exhibition-box-test">松江大学城福利商城</div>
				</div>
			</div>
		</div>

		<div class="Exhibition" style="background-color: transparent;">
			<div class="tit" style="margin-bottom: 0.4rem;">
				分享
			</div>
			<div class="Exhibition-box" style="display: flex;flex-wrap: wrap;justify-content: space-between;">
				<div class="shareText" v-for="(item,index) in shareList" :key="index">
					<div class="shareText-no">{{index+1}}</div>
					<span class="shareText-title">{{item.title}}</span>
					<span>{{item.lable}}</span>
					<span>{{item.address}}</span>
					<span>{{item.time}}</span>
				</div>
				
			</div>
		</div>

		<div class="Topbanner6 flex">
			<div class="Topbanner6-body">
				<div class="Topbanner6-body-phone">
					<img class="Top6-phone" src="../assets/img/phone-y.png">
					<span class="Top6-phone-span">联系电话：400-821-6000</span>
				</div>
				<div class="Topbanner6-body-input">
					<div class="Top6-input">联系我们，为销售加速</div>
					<div class="Top6-but" @click="applyFromshow = true">马上联系</div>
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/bj-4.png">
		</div>

	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	export default {
		data() {
			return {
				applyFromshow: false,
				typicalCaseIndex: 1,
				typicalCaseIndexLeft: 2,
				typicalCaseIndexRight: 2,
				num: 0, // 默认显示第几个
				animateTime: 3000, // 要和 css 的过渡一致
				
				shareList: [{
					title: '分享：采购',
					lable: '分享内容：XXXX项目采购OA系统，物流行业客户，软件成熟度越高越好',
					address: '分享地点：上海',
					time: '分享持续：3周'
				},
				{
					title: '分享：采购',
					lable: '分享内容：XXXX公司定制化采购内部总衔接系统',
					address: '分享地点：上海',
					time: '分享持续：2周'
				},
				{
					title: '分享：采购',
					lable: '分享内容：XXXX公司采购存储5台，具体配置及价格请通过平台',
					address: '分享地点：上海',
					time: '分享持续：2周'
				},
				{
					title: '分享：采购',
					lable: '分享内容：XXXX公司采购信创整机，容量3T，维保增加1年，型号价格请报价',
					address: '分享地点：上海',
					time: '分享持续：3周'
				}],
				
				typicalCaseList: [{
						imgs: require('../assets/img/typicalCaseimg_1.png')
					},
					{
						imgs: require('../assets/img/typicalCaseimg_2.png')
					},
					// {
					// 	imgs: require('../assets/img/typicalCaseimg_2.png')
					// }
				],

				list: [{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					},
					{
						imgs: require('../assets/img/commodity.png'),
						title: '年销售额突破10亿，这家顶尖的元器件增值服务商，究竟是何方神圣？',
						lable: '力瀚产品很便捷，能帮助我们找到精准目标客 户，智能机器人帮助我们快速筛选意向客户。'
					}
				]
			}
		},
		components: {
			// Canvas，
			applyFrom,
			swiper,
			swiperSlide
		},
		computed: {

		},
		mounted() {
			// this.play() // 初始的时候加载
		},
		methods: {
			close() {
				this.applyFromshow = false;
			},

			imgR() {
				this.typicalCaseIndex = this.typicalCaseIndexRight;
				this.Right();
			},

			Left() {

				// console.log('left',this.typicalCaseIndex)
				switch (this.typicalCaseIndex) {
					case 1:
						this.typicalCaseIndex = 2;
						this.typicalCaseIndexLeft = 1;
						this.typicalCaseIndexRight = 1;
						break;
					case 2:
						this.typicalCaseIndex = 1;
						this.typicalCaseIndexLeft = 2;
						this.typicalCaseIndexRight = 2;
						break;
						// case 3:
						// 	this.typicalCaseIndex = 2;
						// 	this.typicalCaseIndexLeft = 1;
						// 	this.typicalCaseIndexRight = 3;
						// 	break;
				}
				// this.typicalCaseIndex --;
			},
			Right() {
				// console.log('right',this.typicalCaseIndex)
				switch (this.typicalCaseIndex) {
					case 1:
						this.typicalCaseIndex = 2;
						this.typicalCaseIndexLeft = 1;
						this.typicalCaseIndexRight = 1;
						break;
					case 2:
						this.typicalCaseIndex = 1;
						this.typicalCaseIndexRight = 2;
						this.typicalCaseIndexLeft = 2;
						break;
						// case 3:
						// 	this.typicalCaseIndex = 1;
						// 	this.typicalCaseIndexLeft = 3;
						// 	this.typicalCaseIndexRight = 2;
						// 	break;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		.casebody {
			width: 90% !important;
		}

		.typicalCase-box-centimg {
			width: 70% !important;
		}

		.el-button.is-circle {
			padding: 0.12rem !important;
		}

		.swiper-button-prev {
			left: 0.8rem !important;
			width: 0.27rem !important;
			height: 0.44rem !important;
			margin-top: 0 !important;
			background-size: 100%;
		}

		.swiper-pagination-bullets {
			bottom: 0.1rem !important;
		}

		.swiper-pagination-bullet {
			width: 0.08rem !important;
			height: 0.08rem !important;
		}

		.swiper-button-next {
			right: 0.8rem !important;
			width: 0.27rem !important;
			height: 0.44rem !important;
			margin-top: 0 !important;
			background-size: 100%;
		}

		.Topbanner6 {
			height: 2rem;
			// padding: 0.3rem 0;
		}

		.Exhibition-box-img {
			width: 1.9rem !important;
		}
	}

	.pagesbox {
		width: 100%;
		background-color: #F7F7F7;
	}

	// img {
	// 	width: 100%;
	// }

	.banner {
		width: 100%;
		position: relative;
		z-index: 101;

		img {
			width: 100%;
		}

		.bannerbox {
			width: 70%;
			height: 100%;
			position: absolute;
			z-index: 10;
			font-size: 0.17rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 0.39rem;
			left: 0;
			right: 0;
			margin: 0 auto;
			flex-direction: column;
			text-align: left;
			align-items: initial;


			.sqsy {
				width: 1.80rem;
				height: 0.60rem;
				border-radius: 6px;
				// margin: 0 auto;
				margin-top: 0.20rem;
				background-image: url(../assets/img/sqsy.png);
				font-size: 0.25rem;
				text-align: center;
				line-height: 0.60rem;
			}

			.bannerbox-tit {
				font-size: 0.25rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.39rem;
			}
		}
	}

	.typicalCase {
		padding: 0.60rem 0;

		.tit {
			width: 100%;
			height: 0.80rem;
			line-height: 0.80rem;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
		}

		.cell-le-lab {
			color: #333;
			font-size: 0.12rem;
			transition: .3s ease-in-out;
			-webkit-transition: .3s ease-in-out;
		}

		.typicalCase-box {
			width: 100%;
			margin-top: 0.6rem;
			overflow-x: hidden;

			img {
				width: 60%;
			}

			.typicalCase-box-img {
				// width: 50%;
				opacity: .3;
			}

			.typicalCase-box-R {}
		}
	}



	.Exhibition {
		padding: 0.60rem 0;
		background-color: #fff;

		.tit {
			width: 100%;
			height: 0.80rem;
			line-height: 0.80rem;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
		}

		.Exhibition-box {
			width: 70%;
			margin: 0 auto;

			.Exhibition-box-test {
				font-size: 0.18rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
				margin-top: 0.2rem;
			}

			.shareText {
				width: 41%;
				display: flex;
				flex-direction: column;
				font-size: 0.14rem;
				text-align: left;
				line-height: 0.4rem;
				color: #666;
				margin-bottom: 0.5rem;
				border-radius: 0.1rem;
				padding: 0.1rem 0.2rem;
				background-color: #fff;
				box-shadow: 0.01rem 0.02rem 0.05rem 0.01rem #ccc;
				position: relative;
				.shareText-no{
					width: 0.3rem;
					height: 0.3rem;
					position: absolute;
					left: -0.15rem;
					top: -0.15rem;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 0.01rem solid #ccc;
				}
				
				.shareText-title{
					font-size: 0.18rem;
					font-weight: bold;
					color: #333;
				}
			}
		}
	}

	.casebody {
		width: 50%;
		margin: 0 auto;

		.tit {
			width: 100%;
			height: 2.00rem;
			line-height: 2.00rem;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
		}

		.casebody-cent {
			width: 100%;
			flex-wrap: wrap;
			justify-content: flex-start;

			.casebody-cent-cell {
				width: 31%;
				text-align: left;
				margin-right: 2%;
				margin-bottom: 0.20rem;
				// height: 280px;
				box-shadow: 0px 1px 12px 0px rgba(174, 174, 174, 0.2);
				border-radius: 4px;
			}
		}

		.more {
			width: 100%;
			// margin: 40px 0;
			padding: 0.40rem 0;

			.more-cell {
				width: 1.90rem;
				line-height: 0.50rem;
				height: 0.50rem;
				margin: 0 auto;
				background-image: url(../assets/img/more.png);
				background-size: 100%;
				font-size: 0.24rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #2D8BF9;
			}
		}
	}

	.enterprise {
		width: 100%;
		background-color: #fff;

		.tit {
			width: 100%;
			height: 1.00rem;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
			line-height: 1.00rem;
		}
	}

	.Topbanner6 {
		width: 100%;
		position: relative;
		z-index: 2;

		.Topbanner6-body {
			position: absolute;
			left: 0;
			right: 0;
			// width: 60%;
			margin: 0 auto;

			.Topbanner6-body-phone {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.25rem;
				color: #fff;
				padding: 0.20rem 0;

				// margin-top: 0.40rem;
				.Top6-phone {
					width: 0.32rem;
					height: 0.32rem;
				}

				.Top6-phone-span {
					margin-left: 0.20rem;
				}
			}

			.Topbanner6-body-input {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.Top6-input {
					width: 5.00rem;
					height: 0.60rem;
					line-height: 0.60rem;
					font-size: 0.20rem;
					padding-left: 0.20rem;
					color: #fff;
					border: 1px solid #fff;

				}

				.Top6-but {
					width: 1.60rem;
					height: 0.62rem;
					line-height: 0.62rem;
					background-color: #fff;
					margin-left: 0.20rem;
					text-align: center;
					color: #188cf3;
					font-size: 0.25rem;

				}
			}
		}
	}
</style>

<style type="text/css">
	.el-carousel__container {
		width: 100%;
		/* height: 6.56rem; */
	}

	.el-carousel--horizontal {
		width: 100%;

	}

	.swiper-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/* .swiper-slide {
		width: 1250px !important;
	} */

	.swiper-container-horizontal>.swiper-scrollbar {
		bottom: 0 !important;
	}

	.swiper-scrollbar {
		background-color: transparent !important;
	}

	/* .swiper-wrapper{
		justify-content: center;
	} */

	/* .swiper-slide {
		width: 1500px !important;
	}
 */

	.el-carousel__arrow--right {
		right: 5.6rem !important;
	}

	.swiper-button-prev {
		left: 2rem !important;
	}

	.swiper-button-next {
		right: 2rem !important;
	}

	.swiper-pagination-bullets {
		width: 90% !important;
	}

	@media screen and (max-width:750px) {
		.el-carousel__container {
			height: 2rem !important;
		}

		.typicalCase-box-img {
			width: 4rem !important;
		}
	}
</style>
